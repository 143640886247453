<ng-container *ngIf="productDetails$ | async as product">
    <ng-container *ngIf="productOption$ | async as options">
        <ng-container *ngIf="options?.obsoleteFlag === false && roleFlag">
            <div class="row justify-content-end mt-4">
                <div class="col-lg-6 col-12">
                    <div class="quantity-selector d-flex flex-wrap flex-md-nowrap flex-lg-wrap flex-xl-nowrap justify-content-end">
                        <input
                            type="number"
                            id="quantity"
                            class="form-control"
                            step="{{step}}"
                            name="quantity"
                            min="{{minQuantity}}"
                            max="{{maxQuantity}}"
                            [(ngModel)]="quantityValue"
                            [value]="isAddedToCart ? minQuantity : quantityValue"
                            (input)="checkMaxQty()"
                            (blur)="verifyQty()"
                            [disabled]="isMaxLmtReached | async"
                        />
                        <button
                            type="button"
                            class="cstm-btn-primary"
                            (click)="onAddToCart(options?.code)"
                            [disabled]="isMaxLmtReached | async"
                        >
                            <span *ngIf="showSpinner == 0">
                                {{options?.quotationFlag
                                ? ("productDetail.requestQuotation" | cxTranslate)
                                : ("productDetail.addToCart" | cxTranslate)}}
                            </span>
                            <ng-container>
                                <i *ngIf="showSpinner == 1" class="fa fa-spinner fa-spin fa-lg"></i>
                                <i *ngIf="showSpinner == 2" class="fa fa-check fa-lg"></i>
                            </ng-container>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>